import axiosInstance from './base.api';
import {Decision, DecisionsResponse} from "../types/decisions";

interface PaginationInfo {
    currentPage: number;
    pageSize: number;
}

export interface RankFilters {
    id: string;
    criteria: {
        id: string;
        name: string;
        importance: number;
        value: number | { name: string, value: number }[];
    }[];
}

// const IMAGE_BASE_URL = 'https://legacy.poc.sparkdit.com/img/appicons';
const IMAGE_BASE_URL = 'https://storage.sparkdit.com/img/appicons';
export const DEFAULT_IMAGE_URL = `${IMAGE_BASE_URL}/398.png`;

const AUTH_TOKEN = sessionStorage.getItem('AUTH_TOKEN');

const _transformDecisionImageUrl = (imageUrl: string): string => {
    if (!imageUrl) {
        return DEFAULT_IMAGE_URL;
    }

    let icon = `${IMAGE_BASE_URL}/${imageUrl}`;
    if (!icon.endsWith('.png')) {
        icon += '.png';
    }
    return icon;
};

const _transformDecisions = (decisions: DecisionsResponse['data']): Decision[] => {
    return decisions.map(decision => {
        return {
            id: decision.id,
            name: decision.name,
            author: decision.user,
            image: _transformDecisionImageUrl(decision.icon),
        }
    })
};

const _generatePaginationQuery = (pageDetails: PaginationInfo) => {
    return `page_size=${pageDetails.pageSize}&current_page=${pageDetails.currentPage}`;
}

export const getPopularDecisions = async (pageDetails: PaginationInfo = {currentPage: 1, pageSize: 10}) => {
    try {
        const paginationQuery = _generatePaginationQuery(pageDetails);
        const response = await axiosInstance.get<DecisionsResponse>(`decisions/list/popular?${paginationQuery}`);
        return {
            decisions: _transformDecisions(response.data.data),
            currentPage: response.data.current_page,
            pageSize: response.data.page_size,
            total: response.data.total,
            totalPages: response.data.total_pages,
        };
    } catch (error) {
        console.error('Error fetching user:', error);
        throw error;
    }
};

export const getTrendingDecisions = async (pageDetails: PaginationInfo = {currentPage: 1, pageSize: 10}) => {
    try {
        const paginationQuery = _generatePaginationQuery(pageDetails);
        const response = await axiosInstance.get<DecisionsResponse>(`decisions/list/trend?${paginationQuery}`);
        return {
            decisions: _transformDecisions(response.data.data),
            currentPage: response.data.current_page,
            pageSize: response.data.page_size,
            total: response.data.total,
            totalPages: response.data.total_pages,
        };
    } catch (error) {
        console.error('Error fetching user:', error);
        throw error;
    }
};

export const getDecisionsByCategory = async (params: PaginationInfo & { category: string } = {
    currentPage: 1,
    pageSize: 10,
    category: ''
}) => {
    try {
        const paginationQuery = _generatePaginationQuery(params);
        const response = await axiosInstance.get<DecisionsResponse>(
            `/decisions/list/bycategory?category=${params.category}&${paginationQuery}`
        );
        return {
            decisions: _transformDecisions(response.data.data || []),
            currentPage: response.data.current_page,
            pageSize: response.data.page_size,
            total: response.data.total,
            totalPages: response.data.total_pages,
        };
    } catch (error) {
        console.error('Error fetching user:', error);
        throw error;
    }
};

export const getMyDecisions = async () => {
    try {
        const response = await axiosInstance.get(`/decisions/list/my`);
        return response.data;
    } catch (error) {
        console.error('Error fetching user:', error);
        throw error;
    }
};

export const getFriendsDecisions = async () => {
    try {
        const response = await axiosInstance.get(`decisions/list/friends`);
        return response.data;
    } catch (error) {
        console.error('Error fetching user:', error);
        throw error;
    }
};


export const getDecision = async (id: number | string) => {
    // try {
        // const response = await axiosInstance.get(`/decisions/${id}`);
        // return response.data;
        return {
            id: 291,
            name: 'Car Selection UX J1 D1 I1',
            description: 'Tradeoff Based Car Selection.',
            image: 'https://dev.ai.sparkdit.com/img/appicons/291.png'
        }
    // } catch (error) {
    //     // console.error('Error fetching app:', error);
    //     // throw error;
    // }
};

export const getDecisionCriteria = async (id: number | string) => {
    try {
        const response = await axiosInstance.get(`/criteria?app_id=${id}`, {
            headers: {
                'api-token': AUTH_TOKEN,
            }
        });

        const firstTechnoFieldIndex = response.data.fields.findIndex((item: {
            technicalField: string
        }) => item.technicalField === "true");
        return {
            ...response.data,
            fields: response.data.fields.slice(0, firstTechnoFieldIndex),
            technoFields: response.data.fields.slice(firstTechnoFieldIndex + 1),
            icon: _transformDecisionImageUrl(response.data.icon),
        };
    } catch (error) {
        console.error('Cannot get decisions:', error);
        throw error;
        // return mockAppCriteria;
    }
};

export const rankApps = async ({ id, criteria }: RankFilters, page_size: number, page: number): Promise<any> => {
    try {
        const response = await axiosInstance.post(`/rank?app_id=${id}&page_size=${page_size}&current_page=${page}`, { criteria }, {
            headers: {
                'api-token': AUTH_TOKEN,
            }
        });

        const results = response.data.results.map((data: any) => ({
            ...data,
            icon: _transformDecisionImageUrl(data.icon),
        }));

        const criteriaKey = Object.keys(response.data.criteria);
        const criteriaData: any[] = [];
        criteriaKey.filter((criteriaKey: any) => /^f\d+$/.test(criteriaKey)).forEach(key => {
            criteriaData.push(response.data.criteria[key])
        });

        return {
            ...response.data,
            criteria: criteriaData,
            results,
        };
    } catch (error) {
        console.error('Cannot get the results:', error);
        throw error;
        // return mockAppResults.map((data: any) => ({
        //     ...data,
        //     icon: _transformDecisionImageUrl(data.icon),
        // }));
    }
};

export const rerankApps = async ({ id, criteria }: RankFilters, rerank: string, page_size: number, page: number): Promise<any> => {
    try {
        const response = await axiosInstance.post(`/rerank?app_id=${id}&rerank=${rerank}&page_size=${page_size}&current_page=${page}`, { criteria }, {
            headers: {
                'api-token': AUTH_TOKEN,
            }
        });

        const results = response.data.results.map((data: any) => ({
            ...data,
            icon: _transformDecisionImageUrl(data.icon),
        }));

        const criteriaData: { [key: string]: string } = {};
        const criteriaKey = Object.keys(response.data.criteria);
        criteriaKey.filter((criteriaKey: any) => /^f\d+$/.test(criteriaKey)).forEach(key => {
            criteriaData[key] = response.data.criteria[key].importance;
        });

        return {
            ...response.data,
            criteria: criteriaData,
            results,
        };
    } catch (error) {
        console.error('Cannot get the results:', error);
        throw error;
    }
};
