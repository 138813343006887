import React, {FormEvent, useEffect, useLayoutEffect, useRef, useState} from 'react';
import {Link, useParams} from "react-router-dom";
import {getDecisionCriteria, rankApps, RankFilters, rerankApps} from "../../api/decisions.api";
import Slider from "../slider/Slider";
import {Button, CircularProgress, Pagination} from "@mui/material";
import WhatshotIcon from '@mui/icons-material/Whatshot';
import BasicTabs from "../tabs/tabs";
import './application.scss';

interface AppCriteria {
    id: string,
    name: string,
    description: string,
    fields: {
        name: string | { value: string },
        description: string,
        style: "slider",
        min: string,
        max: string,
        type: "number" | 'select' | 'text',
        id: string,
        importance: string | number,
        suffix: string,
        val: string,
        step: string,
        hide_field: string
    }[],
    technoFields: {
        type: string,
        name: string,
        description: string,
        technicalField: string,
        hide_field: string,
        hide_importance: string,
        id: string,
        matching: string,
        importance: string
    }[],
    user_id: number,
    icon: string,
    config: {
        skin: string,
        rerank_engine: string
    },
    date_created: string,
    date_modified: string,
    run_count: string,
    ranking: string,
    visibility: string,
    category: string,
    Dataset: null,
    Datasets: string[]
}

interface AppResultInfo {
    id: string,
    decision_id: string,
    data: {
        name: string | { name: string, value: string },
        f1: string,
        f2: string,
        f3: string,
        f4: string,
        f5: string
    },
    icon: string,
    score: {
        f1: number,
        f2: number,
        f3: number,
        f4: number,
        f5: number,
        productlink: number,
        actionlink: number,
        remoteimage: number,
        actionbuttontext: number
    },
    rank: number | '-'
}

interface PageInfo {
    current_page: number;
    start: number;
    end: number;
    total: number;
    total_pages: number;
    page_size: number;
}

// const parseStringValuesToNumber = (value: string | undefined) => {
//     if (!value) {
//         return 0;
//     }
//
//     const formatted = parseFloat(value);
//     if (!isNaN(formatted)) {
//         return formatted;
//     }
//
//     return 0;
// }

const Loading = () => {
    return <div style={{ display: 'flex', flex: 1, alignItems: 'center', justifyContent: 'center', height: '100%'}}>
        <CircularProgress size={64} />
    </div>;
};

const allowedSkins = ['skin3', 'skin4'];

function renderResultsSkin3(results: AppResultInfo[], criteriaData: AppCriteria, onRerank: (rerankProductId: string) => (undefined | Promise<any>)) {
    return <>
        {results.map((result, index) => (
            <div className='app-results-item' key={result.id}>
                {/*<img src={result.icon}*/}
                {/*     alt={typeof result.data.name !== 'object' ? result.data.name : result.data.name.value}/>*/}
                <div className="image-container" style={{backgroundImage: `url('${result.icon}')`}}/>
                <div className='app-criteria-item-details'>
                    <div className='app-criteria-item-details-rank'>
                        <div className='app-criteria-item-details-rank-bar'
                             style={{width: result.rank + '%'}}/>
                        <div className='app-criteria-item-details-rank-rate'>
                            {result.rank === '-' ? '' : result.rank + '%'}
                        </div>
                    </div>
                    <div>
                        <h3>{typeof result.data.name !== 'object' ? result.data.name : result.data.name.value}</h3>
                        <div className='app-criteria-item-details-specs'>
                            {Object.keys(result.data).map((key) => {
                                if (!/^f\d+$/.test(key)) {
                                    return null;
                                }
                                // @ts-ignore
                                const {name, value} = result.data[key];
                                return (
                                    <div className='app-criteria-item-details-specs-spec' key={result.id + key}>
                                        <span>{name}</span>
                                        <span>{value}</span>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                    <div className='app-criteria-item-details-ctas'>
                        {/*<Button className='app-criteria-item-details-ctas-cta'*/}
                        {/*        variant='contained'>{criteriaData.technoFields.length}</Button>*/}
                        {index !== 0 && (
                            <Button
                                className='app-criteria-item-details-ctas-cta'
                                variant='contained'
                                onClick={() => onRerank(result.id)}
                                startIcon={<WhatshotIcon/>}
                            >
                                To top
                            </Button>
                        )}
                    </div>
                </div>
            </div>
        ))}
    </>;
}

function renderResultsSkin4(results: AppResultInfo[], criteriaData: AppCriteria, onRerank: (rerankProductId: string) => (undefined | Promise<any>)) {
    return <div className='results-skin4'>
        {results.map((result, index) => (
            <div className='app-results-item' key={result.id}>
                <div className="image-container" style={{backgroundImage: `url('${result.icon}')`}}/>
                {/*<img*/}
                {/*    src={result.icon}*/}
                {/*     alt={typeof result.data.name !== 'object' ? result.data.name : result.data.name.value}*/}
                {/*/>*/}
                <div className='app-criteria-item-details'>
                    <div className='app-criteria-item-details-rank'>
                        <div className='app-criteria-item-details-rank-bar'
                             style={{width: result.rank + '%'}}/>
                        <div className='app-criteria-item-details-rank-rate'>
                            {result.rank === '-' ? <>&nbsp;</> : result.rank + '%'}
                        </div>
                    </div>
                    <div>
                        <h3>{typeof result.data.name !== 'object' ? result.data.name : result.data.name.value}</h3>
                        <div className='app-criteria-item-details-specs'>
                            {Object.keys(result.data).map((key) => {
                                if (!/^f\d+$/.test(key)) {
                                    return null;
                                }
                                // @ts-ignore
                                const { name, value } = result.data[key];
                                return (
                                    <div className='app-criteria-item-details-specs-spec' key={result.id + key}>
                                        <span>{name}</span>
                                        <span>{value}</span>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                    <div className='app-criteria-item-details-ctas'>
                        {/*<Button className='app-criteria-item-details-ctas-cta'*/}
                        {/*        variant='contained'>Add to Cart</Button>*/}
                        {index !== 0 && (
                            <Button
                                className='app-criteria-item-details-ctas-cta'
                                variant='contained'
                                onClick={() => onRerank(result.id)}
                                startIcon={<WhatshotIcon/>}
                            >
                                To top
                            </Button>
                        )}
                    </div>
                </div>
            </div>
        ))}
    </div>;
}

const initialAppCriteria: AppCriteria = {
    id: '',
    name: '',
    category: '',
    config: {
        skin: '',
        rerank_engine: '',
    },
    Dataset: null,
    Datasets: [],
    date_created: '',
    date_modified: '',
    run_count: '',
    description: '',
    fields: [],
    technoFields: [],
    icon: '',
    ranking: '',
    user_id: 0,
    visibility: ''
};

const transformCriteriaData = (criteria: AppCriteria): RankFilters => {
    return {
        id: criteria.id,
        criteria: criteria.fields.slice(0, 5).map(field => ({
            id: field.id,
            name: field.name as string,
            importance: +field.importance,
            value: field.type === 'select'
                ? Object.keys(field.val).map(key => ({
                    name: key,
                    // @ts-ignore
                    value: +field.val[key],
                }))
                : field.val as unknown as number,
        })),
    }
};

const Application = () => {
    const {id} = useParams<{ id: string }>();
    const formRef = useRef<HTMLFormElement>(null);
    const [criteriaLoading, setCriteriaLoading] = useState<boolean>(true);
    const [resultLoading, setResultLoading] = useState<boolean>(true);
    const initialCriteriaData = useRef<AppCriteria>(initialAppCriteria);
    const [criteriaData, setCriteriaData] = useState<AppCriteria>(initialAppCriteria);
    const [results, setResults] = useState<AppResultInfo[]>([]);
    const [pageInfo, setPageInfo] = useState<PageInfo>({
        page_size: 9,
        current_page: 1,
        start: 0,
        end: 0,
        total: 0,
        total_pages: 1,
    });
    const isSkinAllowed = allowedSkins.includes(criteriaData.config.skin);

    useLayoutEffect(() => {
        if (id) {
            getDecisionCriteria(id)
                .then(response => {
                    initialCriteriaData.current = response;
                    setCriteriaData(response);
                })
                .finally(() => setCriteriaLoading(false));
        }

    }, [id]);

    useEffect(() => {
        if (criteriaData.id && isSkinAllowed) {
            rankApps(transformCriteriaData(criteriaData), pageInfo.page_size, pageInfo.current_page)
                .then(response => {
                    setResults(response.results);
                    setPageInfo({
                        current_page: response.current_page,
                        start: response.start,
                        end: response.end,
                        total: response.total,
                        total_pages: response.total_pages,
                        // page_size: response.page_size,
                        page_size: pageInfo.page_size,
                    })
                })
                .finally(() => setResultLoading(false));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [criteriaData.id]);

    const onCriteriaApply = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        e.stopPropagation();
        setResultLoading(true);
        rankApps(transformCriteriaData(criteriaData), pageInfo.page_size, 1)
            .then(response => {
                setResults(response.results);
                setPageInfo({
                    current_page: response.current_page,
                    start: response.start,
                    end: response.end,
                    total: response.total,
                    total_pages: response.total_pages,
                    // page_size: response.page_size,
                    page_size: pageInfo.page_size,
                })
            })
            .finally(() => setResultLoading(false));
    };

    const onRerank = (rerankProductId: string) => {
        setResultLoading(true);
        rerankApps(transformCriteriaData(criteriaData), rerankProductId, pageInfo.page_size, pageInfo.current_page)
            .then(response => {
                setResults(response.results);
                setCriteriaData(criteriaData => ({
                    ...criteriaData,
                    fields: criteriaData.fields.map(field => ({
                        ...field,
                        importance: response.criteria[field.id],
                    })),
                }));
                setPageInfo({
                    current_page: response.current_page,
                    start: response.start,
                    end: response.end,
                    total: response.total,
                    total_pages: response.total_pages,
                    // page_size: response.page_size,
                    page_size: pageInfo.page_size,
                })
            })
            .finally(() => setResultLoading(false));
    };

    const onResetTradeOffs = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.stopPropagation();
        event.preventDefault();
        if (JSON.stringify(criteriaData.fields) === JSON.stringify(initialCriteriaData.current.fields)) {
            return;
        }

        setCriteriaData({ ...initialCriteriaData.current });
        setResultLoading(true);
        rankApps(transformCriteriaData(initialCriteriaData.current), pageInfo.page_size, pageInfo.current_page)
            .then(response => {
                setResults(response.results);
                setPageInfo({
                    current_page: response.current_page,
                    start: response.start,
                    end: response.end,
                    total: response.total,
                    total_pages: response.total_pages,
                    // page_size: response.page_size,
                    page_size: pageInfo.page_size,
                })
            })
            .finally(() => setResultLoading(false));
    };

    const onSliderChange = (newValue: number, idx: number) => {
        const fields = criteriaData.fields;

        setCriteriaData({
            ...criteriaData,
            fields: [
                ...fields.slice(0, idx),
                {
                    ...fields[idx],
                    importance: newValue,
                },
                ...fields.slice(idx + 1),
            ]
        })
    };

    const onPageChange = (page: number) => {
        setResultLoading(true);
        rankApps(transformCriteriaData(criteriaData), pageInfo.page_size, page)
            .then(response => {
                setResults(response.results);
                setPageInfo({
                    current_page: response.current_page,
                    start: response.start,
                    end: response.end,
                    total: response.total,
                    total_pages: response.total_pages,
                    // page_size: response.page_size,
                    page_size: pageInfo.page_size,
                })
            })
            .finally(() => setResultLoading(false));
    }

    if (!criteriaLoading && !isSkinAllowed) {
        return (
            <div className='application'>
                <div className='warning-banner'>
                    This application is under construction. Visit <Link
                    to={`https://legacy2.sparkdit.com/decisions/view/${id}`} target='_blank'>the old
                    version</Link> of
                    the application.
                </div>
            </div>
        );
    }

    const renderMethods = {
        skin3: renderResultsSkin3,
        skin4: renderResultsSkin4,
    };

    return (
        <div className='application'>
            <div className='warning-banner'>
                Visit <Link to={`https://dev.ai.sparkdit.com/decisions/view/${id}`} target='_blank'>the old
                version</Link> of
                the application.
            </div>

            {criteriaLoading ? (
                <Loading/>
            ) : (
                <>
                    <div className='application-data'>
                        <img src={criteriaData.icon} alt={criteriaData.name}/>
                        <div className='app-info'>
                            <h1 className='app-name'>{criteriaData.name}</h1>
                            <div className='app-description'>{criteriaData.description}</div>
                        </div>
                    </div>
                    <div className='application-details'>
                        <div className='app-criteria'>
                            {criteriaLoading ? (
                                <Loading/>
                            ) : (
                                <form onSubmit={onCriteriaApply} ref={formRef}>
                                    <BasicTabs
                                        tabNames={['Importance']}
                                        tabs={[
                                            <>
                                                {criteriaData.fields.map(({id, name, importance}, idx) => (
                                                    <div key={id} className='app-criteria-field'>
                                                        <label>{typeof name !== 'object' ? name : name.value}
                                                            <Slider
                                                                name={id}
                                                                min={0}
                                                                max={100}
                                                                step={1}
                                                                value={+criteriaData.fields[idx].importance}
                                                                onChange={(e, value) => onSliderChange(value as number, idx)}
                                                            />
                                                        </label>
                                                    </div>
                                                ))}
                                            </>,
                                            <>
                                                settings
                                            </>,
                                        ]}
                                    />
                                    <div className='app-criteria-actions'>
                                        <Button
                                            className='app-criteria-actions-action'
                                            type='submit'
                                            variant='contained'
                                        >
                                            Decide
                                        </Button>
                                        <Button
                                            className='app-criteria-actions-action'
                                            variant='outlined'
                                            type='button'
                                            onClick={onResetTradeOffs}
                                        >
                                            Reset
                                        </Button>
                                    </div>
                                </form>
                            )}
                        </div>
                        <div className='app-results'>
                            {resultLoading ? (
                                <Loading/>
                            ) : (
                                <>
                                    <h3>Results ({pageInfo.total})</h3>
                                    <div>
                                        {/*
                                        // @ts-ignore */}
                                        {renderMethods[criteriaData.config.skin](results, criteriaData, onRerank)}
                                    </div>
                                    <div className='app-results-page-info'>
                                        {/*Displayed products: <b>{pageInfo.start}</b> to <b>{pageInfo.end}</b> of <b>{pageInfo.total}</b>.*/}
                                        <Pagination
                                            page={pageInfo.current_page}
                                            count={pageInfo.total_pages} shape="rounded" onChange={(event, page) => onPageChange(page)}
                                        />
                                    </div>
                                </>
                            )}
                        </div>
                    </div>
                </>
            )}
        </div>
    );
};

export default Application;