import React, {useEffect, useRef, useState} from 'react';
import headerLogo from "../../assets/images/slogo.svg";
import './header.scss';
import {Link, useNavigate} from "react-router-dom";

const headerLinks = [
    {label: 'Terms of use', link: 'https://www.sparkdit.com/legal/terms-of-use'},
    {label: 'Features', link: 'https://ai.sparkdit.com/webpages/view/1'},
    {label: 'Support', link: 'https://ai.sparkdit.com/webpages/view/3'},
    {label: 'Pricing', link: 'https://ai.sparkdit.com/webpages/view/4'},
]

const LOGIN_URL = 'https://platform.sparkdit.com/login';
const REGISTRATION_URL = 'https://platform.sparkdit.com/register';

const Header: React.FC = () => {
    const iframeRef = useRef<HTMLIFrameElement>(null);
    const navigate = useNavigate();
    const [mobileNavActive, setMobileNavActive] = useState(false);

    const toggleMobileNav = () => {
        setMobileNavActive(!mobileNavActive);
    };

    const getAuthEventListener = (event: any) => {
        if (event.data && event.data.event === 'answer') {
            let token = event.data.data;
            if (token) {
                sessionStorage.setItem('AUTH_TOKEN', token);
            }
        }
    };

    const onIframeLoad = () => {
        iframeRef.current?.contentWindow?.postMessage({ event: 'getAuth' }, '*');
    }

    useEffect(() => {
        window.addEventListener('message', getAuthEventListener);

        return () => window.removeEventListener('message', getAuthEventListener);
    }, []);

    return (
        <header className="header">
            <div className="header__logo">
                <img src={headerLogo} alt="logo" onClick={() => navigate('/')}/>
            </div>
            <nav className={`header__nav ${mobileNavActive ? 'active' : ''}`}>
                {headerLinks.map(item => (
                    <Link key={item.label} to={item.link} className="header__link">{item.label}</Link>
                ))}
                <div className="header__buttons">
                    <Link
                        className="header__button header__button--signup"
                        to={REGISTRATION_URL}
                    >
                        Sign up
                    </Link>
                    <Link
                        className="header__button header__button--login"
                        to={LOGIN_URL}
                    >
                        Login
                    </Link>
                </div>
            </nav>
            {/*<div className="header__menu-icon" onClick={toggleMobileNav}>☰</div>*/}
            <div className="header__menu-icon" onClick={toggleMobileNav}>{mobileNavActive ? <>&times;</> : <>&#9776;</>}</div>
            <iframe
                style={{ display: "none" }}
                title='alifhdfkuivbweygfwuielfaks'
                ref={iframeRef}
                src='https://platform.sparkdit.com/auth'
                onLoad={onIframeLoad}
            />
        </header>
    );
};

export default Header;
