import React from 'react';
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import {ToastContainer} from "react-toastify";
import Header from "./components/header";
import Footer from "./components/footer";
import Application from "./components/application";
import Applications from "./components/applications";

import 'react-loading-skeleton/dist/skeleton.css'
import 'react-toastify/dist/ReactToastify.css';
import './app.scss';

function App() {
    return (
        <div className='app'>
            <Router>
                <ToastContainer
                    position="bottom-right"
                    autoClose={5000}
                    closeOnClick
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    hideProgressBar
                    theme='colored'
                />
                <Header/>
                <Routes>
                    <Route path="/" element={<Applications/>}/>
                    <Route path="/apps/:id" element={<Application/>}/>
                    <Route path="*" element={<h2 style={{ padding: '0 16px'}}>Coming soon...</h2>}/>
                </Routes>
                <Footer/>
            </Router>
        </div>
    );
}

export default App;


// TODO
// 1. add eslint
// 2. prettier
// 3. setup pre-commit hooks
// 4. add editorConfig
