import React from "react";
import {default as MuiSlider} from '@mui/material/Slider';
import {SliderProps} from "@mui/material/Slider/Slider";
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import Box from "@mui/material/Box";

const Slider = (props: SliderProps) => {
    return (
        <Box display="flex" alignItems="center">
            <ThumbDownIcon fontSize='small' style={{ marginRight: '1rem' }} color='primary'/>
            <MuiSlider
                defaultValue={50}
                aria-label="Default"
                valueLabelDisplay="auto"
                {...props}
            />
            <ThumbUpIcon fontSize='small' style={{ marginLeft: '1rem' }} color='primary' />
            {/*<IconButton style={{ marginLeft: '.5rem' }}>*/}
            {/*    <EditIcon fontSize='small'  />*/}
            {/*</IconButton>*/}
        </Box>

    );
};

export default Slider;